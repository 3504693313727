<template>
    <div :class="[ activated ? 'activated' : 'inactive' ]" >
        <svg xmlns="http://www.w3.org/2000/svg" width="29.526" height="29.526" viewBox="0 0 29.526 29.526">
            <path id="Icon_awesome-globe-americas" data-name="Icon awesome-globe-americas" d="M14.763.562A14.763,14.763,0,1,0,29.526,15.326,14.763,14.763,0,0,0,14.763.562Zm4.9,21.288c-.232.231-.476.473-.673.671a1.423,1.423,0,0,0-.367.638c-.09.337-.163.677-.284,1L17.3,26.952a11.9,11.9,0,0,1-2.539.279V25.6a4.457,4.457,0,0,0-1.347-3.051,1.9,1.9,0,0,1-.558-1.347V19.3a1.9,1.9,0,0,0-.98-1.665c-.855-.473-2.072-1.135-2.906-1.554a8.581,8.581,0,0,1-1.884-1.295l-.048-.043a6.833,6.833,0,0,1-1.075-1.235c-.558-.82-1.468-2.168-2.059-3.044A11.971,11.971,0,0,1,10.05,4.4l1.429.715a.952.952,0,0,0,1.379-.852V3.587a11.9,11.9,0,0,1,1.452-.144l1.685,1.685a.952.952,0,0,1,0,1.347l-.279.279-.616.616a.476.476,0,0,0,0,.673l.279.279a.476.476,0,0,1,0,.673l-.476.476a.477.477,0,0,1-.337.139h-.535a.476.476,0,0,0-.332.135l-.591.574a.477.477,0,0,0-.094.554l.928,1.857a.476.476,0,0,1-.426.689h-.336a.476.476,0,0,1-.312-.117l-.552-.48a.953.953,0,0,0-.926-.185l-1.856.619a.712.712,0,0,0-.093,1.311l.66.33a4.031,4.031,0,0,0,1.8.426c.626,0,1.345,1.625,1.9,1.9h3.974a1.906,1.906,0,0,1,1.347.558l.815.815a1.816,1.816,0,0,1,.532,1.284,2.77,2.77,0,0,1-.817,1.963Zm5.162-5.438a1.429,1.429,0,0,1-.842-.594l-1.07-1.605a1.427,1.427,0,0,1,0-1.585l1.166-1.749a1.427,1.427,0,0,1,.55-.485l.773-.386a11.533,11.533,0,0,1,1.161,6.838Z" transform="translate(0 -0.563)" />
        </svg>
    </div>
</template>

<script>
export default {
    name: 'flagIcon',
    props: {
        activated: Boolean,
    },
    methods: {
        whenActivated() {

        }
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/_config.scss';

.inactive>svg>path {
    fill: $secondary;
}
.activated>svg>path {
    fill: $primary;
}
</style>